import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show.vocEntryModal)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(
      _ctx.show.vocEntryModal.voc_type === _ctx.VOC_NAMES_DICT.organizations
        ? 'EditingVocExtendedModal'
        : 'EditingVocModal'
    ), {
        key: 0,
        mode: _ctx.show.vocEntryModal.mode,
        record_id: _ctx.show.vocEntryModal.record_id,
        "voc-extra-info": _ctx.show.vocEntryModal.vocExtraInfo,
        voc_type: _ctx.show.vocEntryModal.voc_type,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show.vocEntryModal = null))
      }, null, 40, ["mode", "record_id", "voc-extra-info", "voc_type"]))
    : _createCommentVNode("", true)
}